@layer modules, ui, base;
@layer ui {
  /** @define Tag */

.tag_jds-Tag__yv5Xq {
  --Tag-Height: var(--spacer-300);

  display: inline-flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: var(--Tag-Height);
  padding: 0 var(--spacer-150);
  border-radius: var(--Tag-Height);
  color: var(--color-black);
  font-size: var(--minorBody);
  line-height: 1;
}

.tag_jds-Tag--outlined__wr75s {
  outline: 1px solid var(--color-black);
  outline-offset: -1px;
}

.tag_jds-Tag--filled__tUMc3 {
  background: var(--color-grey--100);
}

.tag_jds-Tag--disabled__6BX3e {
  color: var(--color-grey--200);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
.tag_jds-Tag--outlined__wr75s.tag_jds-Tag--disabled__6BX3e {
  outline-color: var(--color-grey--200);
}

.tag_jds-Tag--pink__w7x5x {
  background-color: var(--color-pink--100);
}

.tag_jds-Tag--cyan__m5iaT {
  background-color: var(--color-cyan--100);
}

.tag_jds-Tag--purple__YGKDH {
  background-color: var(--color-purple--100);
}

.tag_jds-Tag--yellow__fgwfT {
  background-color: var(--color-yellow--100);
}

.tag_jds-Tag--magenta__zcD2f {
  background-color: var(--color-magenta--100);
}

.tag_jds-Tag--green__vcVc_ {
  background-color: var(--color-green--100);
}

.tag_jds-Tag--orange__OvZ3j {
  background-color: var(--color-warning--100);
}

.tag_jds-Tag--red__G_RiY {
  background-color: var(--color-error--100);
}

}
@layer ui {
  .sk-Badge_main__AVFxz {
  --sk-Badge-y-padding: var(--sk-space-4);
  --sk-Badge-x-padding: var(--sk-space-8);
  --sk-Badge-label-spacing: var(--sk-space-4);
  --sk-Badge-height: calc(var(--sk-Badge-y-padding) * 2 + var(--sk-typography-data-display-badge-text-typography-m-line-height));

  display: inline-flex;
  box-sizing: border-box;
  flex-grow: 0;
  align-items: center;
  align-self: end;
  justify-content: center;
  min-width: 0; /* Required for overflow: ellipsis; */
  height: var(--sk-Badge-height);
  padding: var(--sk-Badge-y-padding) calc(var(--sk-Badge-x-padding) + var(--sk-Badge-label-spacing));
  overflow: hidden;
  border-radius: var(--sk-radius-20);
  font-family: var(--sk-typography-data-display-badge-text-typography-m-font-family);
  font-weight: var(--sk-typography-data-display-badge-text-typography-m-font-weight);
  gap: var(--sk-Badge-label-spacing);
}

.sk-Badge_label__f1crk {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.sk-Badge_icon__M8ilK {
  flex-shrink: 0;
}

.sk-Badge_main--withIcon__uFfRO {
  padding-right: calc(var(--sk-Badge-x-padding) + var(--sk-Badge-label-spacing));
  padding-left: var(--sk-Badge-x-padding);
}

.sk-Badge_main--medium__d1Pq_ {
  font-size: var(--sk-typography-data-display-badge-text-typography-m-font-size);
  font-weight: var(--sk-typography-data-display-badge-text-typography-m-font-weight);
  line-height: var(--sk-typography-data-display-badge-text-typography-m-line-height);
}

.sk-Badge_main--large__WUCcE {
  --sk-Badge-height: calc(var(--sk-Badge-y-padding) * 2 + var(--sk-typography-data-display-badge-text-typography-l-line-height));

  font-size: var(--sk-typography-data-display-badge-text-typography-l-font-size);
  font-weight: var(--sk-typography-data-display-badge-text-typography-l-font-weight);
  line-height: var(--sk-typography-data-display-badge-text-typography-l-line-height);
}

.sk-Badge_main--tertiary__J_3Du {
  border-width: var(--sk-data-display-badge-size-border-tertiary);
  border-style: solid;
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--primary__yAKxY {
  background-color: var(--sk-data-display-badge-color-background-primary-light);
  color: var(--sk-data-display-badge-color-primary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--primary__yAKxY.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-primary-faded-light);
  color: var(--sk-data-display-badge-color-primary-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--secondary__wgMjW {
  background-color: var(--sk-data-display-badge-color-background-secondary-light);
  color: var(--sk-data-display-badge-color-secondary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--secondary__wgMjW.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-secondary-faded-light);
  color: var(--sk-data-display-badge-color-secondary-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--tertiary__J_3Du {
  border-color: var(--sk-data-display-badge-color-border-tertiary-light);
  background-color: var(--sk-data-display-badge-color-background-tertiary-light);
  color: var(--sk-data-display-badge-color-tertiary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--success__3Jnl2 {
  background-color: var(--sk-data-display-badge-color-background-success-light);
  color: var(--sk-data-display-badge-color-success-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--success__3Jnl2.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-success-faded-light);
  color: var(--sk-data-display-badge-color-success-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--warning__x2jJg {
  background-color: var(--sk-data-display-badge-color-background-warning-light);
  color: var(--sk-data-display-badge-color-warning-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--warning__x2jJg.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-warning-faded-light);
  color: var(--sk-data-display-badge-color-warning-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--error__X5oen {
  background-color: var(--sk-data-display-badge-color-background-error-light);
  color: var(--sk-data-display-badge-color-error-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--error__X5oen.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-error-faded-light);
  color: var(--sk-data-display-badge-color-error-faded-light);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--primary__yAKxY {
  background-color: var(--sk-data-display-badge-color-background-primary-dark);
  color: var(--sk-data-display-badge-color-primary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--primary__yAKxY.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-primary-faded-dark);
  color: var(--sk-data-display-badge-color-primary-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--secondary__wgMjW {
  background-color: var(--sk-data-display-badge-color-background-secondary-dark);
  color: var(--sk-data-display-badge-color-secondary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--secondary__wgMjW.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-secondary-faded-dark);
  color: var(--sk-data-display-badge-color-secondary-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--tertiary__J_3Du {
  border-color: var(--sk-data-display-badge-color-border-tertiary-dark);
  background-color: var(--sk-data-display-badge-color-background-tertiary-dark);
  color: var(--sk-data-display-badge-color-tertiary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--success__3Jnl2 {
  background-color: var(--sk-data-display-badge-color-background-success-dark);
  color: var(--sk-data-display-badge-color-success-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--success__3Jnl2.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-success-faded-dark);
  color: var(--sk-data-display-badge-color-success-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--warning__x2jJg {
  background-color: var(--sk-data-display-badge-color-background-warning-dark);
  color: var(--sk-data-display-badge-color-warning-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--warning__x2jJg.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-warning-faded-dark);
  color: var(--sk-data-display-badge-color-warning-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--error__X5oen {
  background-color: var(--sk-data-display-badge-color-background-error-dark);
  color: var(--sk-data-display-badge-color-error-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--error__X5oen.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-error-faded-dark);
  color: var(--sk-data-display-badge-color-error-faded-dark);
}

}
@layer ui {
  .FixedAspectRatioContainer_fixedAspectRatioContainer__gbFfR {
  position: relative;
  width: 100%;
}

.FixedAspectRatioContainer_fixedAspectRatioContainer--W16H9__xtN5t {
  padding-bottom: 56.25%; /* 16:9 -> 100% * 9 / 16 */
}

.FixedAspectRatioContainer_fixedAspectRatioContainer__inner__Y3epf {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

}
@layer ui {
  .arrow_jds-arrow__GdEXX {
  display: none;
  z-index: 1 !important;
}

@media (min-width: 48rem) {
  .arrow_jds-arrow__GdEXX {
    /* Get the same display as the button with only icon */
    display: inline-flex;
    position: absolute;
    align-self: center;
    transform: translate(-50%, 0);
    border: none;
  }

  .arrow_jds-arrow__GdEXX:focus {
    outline: none !important;
  }

  .arrow_jds-arrow--hidden__tlIYW {
    display: none;
  }
}

}
@layer ui {
  .content_jds-content__separator__bOxyb {
  margin: 0 var(--spacer-100);
  background-color: var(--color-grey--100);
}

@media (min-width: 48rem) {
  .content_jds-content__wrapper__9e0A_ {
    position: relative;
    overflow-y: visible;
  }

  .content_jds-content__separator__bOxyb {
    margin: 0 var(--spacer-150);
  }
}

}
@layer ui {
  .header_jds-header--hidden__e3698 {
  opacity: 0;
}

}
@layer ui {
  .item_jds-item--hidden__QPhMf {
  opacity: 0;
}

}
@layer ui {
  /** @define Paper */

.paper_jds-Paper__W_bcw {
  padding: var(--spacer-300);
  background-color: var(--color-white);
  box-shadow: var(--default-shadow);
}

.paper_jds-Paper--isDark__BtGLj {
  background-color: var(--color-black);
  color: var(--color-white);
}

.paper_jds-Paper--noSpacing__5fBkQ {
  padding: 0;
}

.paper_jds-Paper--noShadow__6sUru {
  box-shadow: none;
}

.paper_jds-Paper--noBackground__9kbgQ {
  background-color: transparent;
}

.paper_jds-Paper--radius__qzXAo {
  border-radius: var(--radius-200);
}

.paper_jds-Paper--radiusRounded__GhgtS {
  border-radius: var(--radius-900);
}

.paper_jds-Paper--isFullWidth__pe_8P {
  padding-right: 0;
  padding-left: 0;
}

.paper_jds-Paper--hasBorder__DxYyT {
  border: var(--border);
}

@media (min-width: 48rem) {
  .paper_jds-Paper__W_bcw:not(.paper_jds-Paper--smallSpacing__GJYWV, .paper_jds-Paper--isFullWidth__pe_8P, .paper_jds-Paper--noSpacing__5fBkQ) {
    padding: var(--spacer-400) var(--spacer-600);
  }
}

}
@layer ui {
  /* @define FormTemplate */

.Section_jds-FormTemplate__section__fTP0Z {
  margin-top: var(--spacer-300);
}

.Section_jds-FormTemplate__section--withTitle__JaQ04 {
  margin-top: var(--spacer-600);
}

.Section_jds-FormTemplate__section--withTitle__JaQ04:first-of-type {
  margin-top: 0;
}

.Section_jds-FormTemplate__paperSection___1G5q {
  /* Override default Paper component padding. */
  padding: var(--spacer-400) !important;
}

/* stylelint-disable plugin/selector-bem-pattern */
.Section_jds-FormTemplate__paperSection___1G5q > *:last-child {
  margin-bottom: 0;
}

/* stylelint-enable plugin/selector-bem-pattern */

}
@layer ui {
  /** @Define Divider */

.divider_jds-Divider__v_d_b {
  margin: 0;
  border: 0;
  border-bottom: var(--border);
}

.divider_jds-Divider--white__D_HWR {
  border-bottom: var(--border--white);
}

}
@layer ui {
  /** @define RichText */

/* Because we need to style some tags inside this rich text */
/* stylelint-disable plugin/selector-bem-pattern */

/* Top and Bottom tag margin */

.RichText_jds-RichText__DUo0M :first-child {
  margin-top: 0;
}

.RichText_jds-RichText__DUo0M :last-child {
  margin-bottom: 0;
}

.RichText_jds-RichText--resetSpacing__uH81J {
  margin: 0;
  padding: 0;
}

/* Anchors */

.RichText_jds-RichText__DUo0M a {
  color: var(--color-black);
  text-decoration: underline;
  text-underline-offset: 3px;
}

.RichText_jds-RichText__DUo0M a:hover {
  color: var(--color-black);
  text-underline-offset: 2px;
  -webkit-text-decoration: underline 2px;
          text-decoration: underline 2px;
}

.RichText_jds-RichText__DUo0M a:focus {
  outline: thin auto var(--color-grey--500);
  outline-offset: 4px;
  color: var(--color-black);
}

.RichText_jds-RichText__DUo0M span > a {
  color: currentcolor;
}

/* Headings */

.RichText_jds-RichText__DUo0M h2,
.RichText_jds-RichText__DUo0M h3 {
  font-family: var(--fontHeading);
}

.RichText_jds-RichText__DUo0M h3 {
  margin: var(--spacer-400) 0 var(--spacer-200) 0;
  font-size: var(--subHeading2);
  font-weight: var(--bold);
  line-height: var(--subHeading2-lh);
}

.RichText_jds-RichText__DUo0M h2 {
  margin: var(--spacer-600) 0;
  font-size: var(--subHeading1);
  font-weight: var(--bold);
  line-height: var(--subHeading1-lh);
}

/* Tables */

.RichText_jds-RichText__DUo0M table, .RichText_jds-RichText__DUo0M th, .RichText_jds-RichText__DUo0M td {
  border: 1px solid var(--color-black);
}

.RichText_jds-RichText__DUo0M td {
  padding: var(--spacer-100);
}

.RichText_jds-RichText__DUo0M table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  word-break: normal;
}

/* Lists */

.RichText_jds-RichText__DUo0M ul {
  padding-inline-start: var(--spacer-400);
  list-style: initial;
}

.RichText_jds-RichText__DUo0M ol {
  padding-inline-start: var(--spacer-400);
}

/* Images */

.RichText_jds-RichText__DUo0M img {
  max-width: 100%;
  border-radius: var(--radius-200);
}

/* Iframes */

.RichText_jds-RichText__DUo0M iframe {
  max-width: 100%;
}

/* Froala Editor Override */

.RichText_jds-RichText__DUo0M .fr-alternate-rows tbody tr:nth-child(2n) {
  background: var(--color-grey--050);
}

.RichText_jds-RichText__DUo0M .fr-dashed-borders,
.RichText_jds-RichText__DUo0M .fr-dashed-borders td,
.RichText_jds-RichText__DUo0M .fr-dashed-borders th {
  border: 1px dashed var(--color-black);
}

.RichText_jds-RichText__DUo0M .fr-hidden-borders,
.RichText_jds-RichText__DUo0M .fr-hidden-borders td,
.RichText_jds-RichText__DUo0M .fr-hidden-borders th {
  border-color: transparent;
}

}
@layer ui {
  .sk-Typography_main__I32O9 {
  margin: unset;
  padding: unset;
}

/**
 * Tokens seems wrong for font families and weights but they are not.
 * The naming constraint came from Token Studio in Figma part where tokens are
 * generated. Token Studio creates as many tokens as there are variants.
 * For example, if we have the following configuration:
 * 3 hierarchy variants
 * 3 weights variants
 * 2 font families
 * Token Studio will create: 3 * 3 * 2 = 18 tokens.
 * Instead, we can handle variants separately and have only 3 + 3 + 2 = 8 tokens.
 * In our case, all Text class names use:
 * - the same font family
 * - the same font weight according to weight variant
 *   (regardless of the font used: body1, body2 etc.).
 */

/**
 * Font families
 */

.sk-Typography_title1__kpKKc,
.sk-Typography_title2___x7oz,
.sk-Typography_display1__01oEX,
.sk-Typography_display2__CnKww {
  font-family: var(--sk-typography-title-1-font-family), helvetica, arial, sans-serif;
}

.sk-Typography_body1__rvFYv,
.sk-Typography_body2__W0CNH,
.sk-Typography_title3__c40PS,
.sk-Typography_caption1__sl2pb,
.sk-Typography_caption1Underline__eZKaw {
  font-family: var(--sk-typography-body-1-regular-font-family), helvetica, arial, sans-serif;
}

/**
 * Weights
 */

.sk-Typography_regular__a_y2X {
  font-weight: var(--sk-typography-body-1-regular-font-weight);
}

.sk-Typography_semiBold__OC7cU {
  font-weight: var(--sk-typography-body-1-semibold-font-weight);
}

.sk-Typography_bold__ICdus {
  font-weight: var(--sk-typography-body-1-bold-font-weight);
}

/**
 * @FIXME: title token has been moved (.title1 uses --…-title-2) for mobile handling.
 *   See with @valentinmichel-jt for what needs to be done to make it more maintainable.
 *   N.B. .title4 is not handled yet and, in this case, it will miss the --…-title-5 token.
 *        We'll also have an n+1 missing token for each title variant added.
 */

.sk-Typography_title1__kpKKc {
  font-size: var(--sk-typography-title-2-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-title-2-line-height);
  text-decoration: none;
}

.sk-Typography_title2___x7oz {
  font-size: var(--sk-typography-title-3-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-title-3-line-height);
  text-decoration: none;
}

.sk-Typography_title3__c40PS {
  font-size: var(--sk-typography-title-4-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-title-4-line-height);
  text-decoration: none;
}

.sk-Typography_body1__rvFYv {
  font-size: var(--sk-typography-body-1-regular-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-body-1-regular-line-height);
  text-decoration: none;
}

.sk-Typography_body2__W0CNH {
  font-size: var(--sk-typography-body-2-regular-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-decoration: none;
}

.sk-Typography_caption1__sl2pb,
.sk-Typography_caption1Underline__eZKaw {
  font-size: var(--sk-typography-caption-1-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-caption-1-line-height);
  text-decoration: none;
}

.sk-Typography_caption1Underline__eZKaw {
  text-decoration: underline;
}

.sk-Typography_display1__01oEX {
  font-size: var(--sk-typography-display-1-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-display-1-line-height);
  text-decoration: none;
}

.sk-Typography_display2__CnKww {
  font-size: var(--sk-typography-display-2-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-display-2-line-height);
  text-decoration: none;
}

@media (min-width: 768px) {
  .sk-Typography_title1__kpKKc {
    font-size: var(--sk-typography-title-1-font-size);
    line-height: var(--sk-typography-title-1-line-height);
  }

  .sk-Typography_title2___x7oz {
    font-family: var(--sk-typography-title-2-font-family), helvetica, arial, sans-serif;
    font-size: var(--sk-typography-title-2-font-size);
    font-weight: var(--sk-typography-title-2-font-weight);
    line-height: var(--sk-typography-title-2-line-height);
  }

  .sk-Typography_title3__c40PS {
    font-size: var(--sk-typography-title-3-font-size);
    line-height: var(--sk-typography-title-3-line-height);
  }
}

}
@layer ui {
  /** @define Pictogram */
.Pictogram_jds-Pictogram__ZG8GF {
  fill: currentcolor;
  vertical-align: middle;
}

.Pictogram_jds-Pictogram--minor__wQY3C {
  width: var(--picto-size-minor); /* 36px */
  height: var(--picto-size-minor); /* 36px */
}

.Pictogram_jds-Pictogram--small__0SrfD {
  width: var(--picto-size-small); /* 48px */
  height: var(--picto-size-small); /* 48px */
}

.Pictogram_jds-Pictogram--medium__z2GTT {
  width: var(--picto-size-medium); /* 60px */
  height: var(--picto-size-medium); /* 60px */
}

.Pictogram_jds-Pictogram--large__TFbj1 {
  width: var(--picto-size-large); /* 96px */
  height: var(--picto-size-large); /* 96px */
}

.Pictogram_jds-Pictogram--major__PoFzE {
  width: var(--picto-size-major); /* 112px */
  height: var(--picto-size-major); /* 112px */
}

}
@layer ui {
  /** @define Status */

.status_jds-Status__I8m97 {
  display: inline-flex;
  align-items: center;
}

.status_jds-Status__I8m97::before {
  content: "";
  display: inline-block;
  flex-shrink: 0;
  width: var(--icon-size-tiny);
  height: var(--icon-size-tiny);
  margin-right: var(--spacer-100);
  border-radius: var(--radius-900);
  vertical-align: middle;
}

.status_jds-Status--active__nZ2DW::before {
  background-color: var(--color-success--500);
}

.status_jds-Status--inactive__yIA4M::before {
  background-color: var(--color-error--500);
}

.status_jds-Status--warning__e_U_q::before {
  background-color: var(--color-warning--500);
}

.status_jds-Status--neutral__wkb7R::before {
  background-color: var(--color-grey--200);
}

}
@layer ui {
  /** @define RadioButton */
.radioButton_jds-RadioButton__S8dNK {
  --RadioButton-diameter: 1.25rem; /* 20px */
  display: inline-flex;
  align-items: center;
  color: var(--color-black);
  font-size: var(--normalBody);
  line-height: var(--normalBodyLineHeight);
  cursor: pointer;
}

.radioButton_jds-RadioButton__input__3wDSo {
  box-sizing: border-box;
  flex-shrink: 0;
  width: var(--RadioButton-diameter);
  height: var(--RadioButton-diameter);
  margin: 0;
  border: 1px solid var(--color-grey--200);
  border-radius: var(--radius-900);
  outline: 0;
  background-color: var(--color-white);
  vertical-align: text-bottom;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.radioButton_jds-RadioButton__input--withLabel__t6GcE {
  margin-right: var(--spacer-100);
}

/* "hover" and "focus" states */

.radioButton_jds-RadioButton__input__3wDSo:not(:disabled):hover,
.radioButton_jds-RadioButton__input__3wDSo:not(:disabled):focus {
  outline: 2px solid var(--color-grey--500);
  outline-offset: -1px;
}

/* "checked" state */

.radioButton_jds-RadioButton__input__3wDSo:checked {
  position: relative;
  border-color: var(--themeMainColor, currentcolor);
  color: var(--color-black);
}

.radioButton_jds-RadioButton__input__3wDSo:checked::after {
  content: "";
  position: absolute;
  top: calc(50% - var(--RadioButton-diameter) / 4);
  left: calc(50% - var(--RadioButton-diameter) / 4);
  width: calc(var(--RadioButton-diameter) / 2);
  height: calc(var(--RadioButton-diameter) / 2);
  border-radius: var(--radius-900);
  background-color: var(--themeMainColor, currentcolor);
}

/* "disabled" state */

.radioButton_jds-RadioButton--isDisabled__W7Ix0 {
  color: var(--color-grey--200);
  cursor: default;
}

.radioButton_jds-RadioButton__input__3wDSo:disabled,
.radioButton_jds-RadioButton__input__3wDSo:disabled:checked::after {
  border-color: var(--color-grey--200);
  background-color: var(--color-grey--100);
  cursor: default;
}

.radioButton_jds-RadioButton__input__3wDSo:disabled:checked::after {
  background-color: var(--color-grey--200);
}

}
@layer ui {
  /** @define Link */

.link_jds-Link__IVm1_ {
  color: var(--color-black);
  text-decoration: underline;
  text-underline-offset: 3px;
}

.link_jds-Link__IVm1_:hover {
  color: var(--color-black);
  text-underline-offset: 2px;
  -webkit-text-decoration: underline 2px;
          text-decoration: underline 2px;
}

.link_jds-Link__IVm1_:focus {
  outline: thin auto var(--color-grey--500);
  outline-offset: 4px;
  color: var(--color-black);
}

.link_jds-Link--button__gHSKU {
  cursor: pointer;
}

.link_jds-Link--minor__dX853 {
  font-size: var(--smallBody);
}

.link_jds-Link--line-height-edito__UWwuK {
  line-height: var(--editoBodyLineHeight);
}

.link_jds-Link--line-height-normal__zGWL1 {
  line-height: var(--normalBodyLineHeight);
}

.link_jds-Link--line-height-small__B_Djg {
  line-height: var(--smallBodyLineHeight);
}

.link_jds-Link--line-height-minor__5ahPx {
  line-height: var(--minorBodyLineHeight);
}

.link_jds-Link--reset__2EcM6,
.link_jds-Link--reset__2EcM6:hover,
.link_jds-Link--reset__2EcM6:active {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

}
@layer ui {
  /** @define ElementMessage */

.elementMessage_jds-ElementMessage__UF2hh {
  --ElementMessage-maxWidth: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: nowrap;
  width: var(--ElementMessage-maxWidth);
  max-width: 100%;
  padding: var(--spacer-200);
  border: 1px solid var(--color-grey--100);
  border-radius: var(--radius-200);
  background-color: var(--color-grey--025);
}

.elementMessage_jds-ElementMessage--success___RllK {
  border-color: var(--color-success--500);
  background-color: var(--color-success--100);
}

.elementMessage_jds-ElementMessage--warning__QHChg {
  border-color: var(--color-warning--500);
  background-color: var(--color-warning--100);
}

.elementMessage_jds-ElementMessage--error__rxXZt {
  border-color: var(--color-error--500);
  background-color: var(--color-error--100);
}

.elementMessage_jds-ElementMessage--info__qN1dC {
  background-color: var(--color-grey--100);
}

.elementMessage_jds-ElementMessage--asNotification__oMCV1 {
  border: none;
  opacity: 1;
  box-shadow: var(--default-shadow);
}

.elementMessage_jds-ElementMessage--unshownNotification__Q7upJ {
  animation: elementMessage_unshown__Xg6Fo 0.2s ease-in forwards;
}

.elementMessage_jds-ElementMessage__content__3zrQF {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 0 var(--spacer-100);
}

.elementMessage_jds-ElementMessage__content__3zrQF::first-letter {
  text-transform: uppercase;
}

.elementMessage_jds-ElementMessage__title__QHDIw {
  font-weight: var(--semiBold);
}

.elementMessage_jds-ElementMessage__text__vIAHS :not([role="heading"]) {
  font-size: var(--smallBody);
}

.elementMessage_jds-ElementMessage__symbol__eHpLc {
  width: var(--icon-size-medium);
}

.elementMessage_jds-ElementMessage__symbol--info__fx2yl {
  color: var(--color-grey--500);
}

.elementMessage_jds-ElementMessage__symbol--success__zq6eG {
  color: var(--color-black);
}

.elementMessage_jds-ElementMessage__symbol--warning__1BYM5 {
  color: var(--color-warning--500);
}

.elementMessage_jds-ElementMessage__symbol--error__6C8LH {
  color: var(--color-error--500);
}

.elementMessage_jds-ElementMessage__symbol--isPictogram__jVhxI {
  width: var(--picto-size-small);
}

.elementMessage_jds-ElementMessage__close__ujD2X {
  align-self: flex-start;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  color: var(--color-black);
  cursor: pointer;
}

@media (min-width: 48rem) {
  .elementMessage_jds-ElementMessage--asNotification__oMCV1 {
    --ElementMessage-maxWidth: 35.25rem; /* 564px */
  }
}

@keyframes elementMessage_unshown__Xg6Fo {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

}
@layer ui {
  /** @define Body */
/* stylelint-disable plugin/selector-bem-pattern */

.modal_jds-Body--withModal__qnH2p {
  overflow-y: hidden;
}

/** @define Modal */

.modal_jds-Modal__gZSZT {
  --Modal-padding: 1.25rem;
  display: flex;
  position: fixed;
  z-index: 1002;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /*
    Do not switch height to vh unit because behavior is inconsistent on mobile device
    See  https://stackoverflow.com/questions/52848856/100vh-height-when-address-bar-is-shown-chrome-mobile and
    https://nicolas-hoizey.com/2015/02/viewport-height-is-taller-than-the-visible-part-of-the-document-in-some-mobile-browsers.html
    for more informations
  */
  height: 100%;
  overflow: hidden;
  animation: modal_show__fwtTO 0.2s ease-in forwards;
  opacity: 0;
}

.modal_jds-Modal__title__BOiYw {
  font-size: var(--title2);
  font-weight: var(--semiBold);
  line-height: var(--title2-lh);
}

/* Fading overlay */
.modal_jds-Modal__background__3lt8K {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background: var(--color-black);
}

[class*="jds-Modal"].modal_jds-Modal__wrapper__B4rkd {
  --Modal-maxWidth: 35.25rem;

  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: var(--Modal-padding);
  overflow-y: auto;
  background-color: var(--color-white);
  color: var(--color-black);
}

.modal_jds-Modal__wrapper--isCentered__b1_g_ {
  justify-content: center;
}

.modal_jds-Modal__wrapper--withIllustration__Oq7X2 {
  align-items: center;
  overflow-y: auto;
}

[class*="jds-Modal"].modal_jds-Modal__wrapper--stickyFooter__kIoXz {
  padding: 0;
}

.modal_jds-Modal__header__ClF7_ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
}

.modal_jds-Modal__header--withIllustration__Ud_rK {
  align-items: center;
}

.modal_jds-Modal__header--withoutIcon__fzjpw {
  max-width: 90%;
}

[class*="jds-Modal"].modal_jds-Modal__header--stickyFooter__icmJv {
  padding-top: var(--Modal-padding);
  padding-left: var(--Modal-padding);
}

.modal_jds-Modal__closeButton__D01W9 {
  position: absolute;
  z-index: 1; /* in front of the Header */
  top: var(--Modal-padding);
  right: var(--Modal-padding);
  padding: 0;
  border: none;
  background: none;
  color: var(--color-black);
  cursor: pointer;
}

.modal_jds-Modal__footer__VnXGC {
  display: inline-flex;
  flex-flow: column-reverse wrap;
  justify-content: flex-end;
  gap: var(--spacer-200);
}

[class*="jds-Modal"].modal_jds-Modal__footer--sticky__UHRRb {
  position: sticky;
  bottom: 0;
  padding: calc(var(--Modal-padding) / 1.5);
  background-color: #ffffff;
  box-shadow: var(--minor-shadow);
}

.modal_jds-Modal__footer--withIllustration__MmxZl {
  align-items: center;
}

.modal_jds-Modal__body___hKfJ {
  position: relative; /* keep it to improve react-select behavior on modal. No clue, but it's better with it */
  padding: var(--spacer-300) 0;
}

.modal_jds-Modal__body--withIllustration__62YIo {
  height: auto;
  padding-top: var(--spacer-100);
  overflow-y: initial;
}

[class*="jds-Modal"].modal_jds-Modal__body--stickyFooter__uOW8j {
  padding-right: var(--Modal-padding);
  padding-left: var(--Modal-padding);
}

/* stylelint-disable-next-line -- force center alignment. */
.modal_jds-Modal__body--withIllustration__62YIo > * {
  align-items: center;
  text-align: center;
}

@media (min-width: 48rem) {
  [class*="jds-Modal"].modal_jds-Modal__wrapper__B4rkd {
    --Modal-padding: var(--spacer-400);
    max-width: var(--Modal-maxWidth);
    /* limit height on desktop to prevent the Modal from overflowing */
    height: auto;
    max-height: 90vh;
    padding: var(--Modal-padding);
    border-radius: var(--radius-400);
  }

  .modal_jds-Modal__footer__VnXGC {
    flex-direction: row;
  }

  [class*="jds-Modal"].modal_jds-Modal__wrapper--stickyFooter__kIoXz {
    padding: 0;
  }
}

@keyframes modal_show__fwtTO {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

}
@layer ui {
  /* @define Note */

.Note_jds-Note__SyY4K {
  display: flex;
  align-items: center;
  padding: var(--spacer-200) var(--spacer-200) var(--spacer-200) var(--spacer-300);
  gap: var(--spacer-200);
  border-radius: var(--radius-200);
}

.Note_jds-Note--loading__OPTYk {
  background-color: var(--color-grey--025) !important;
}

.Note_jds-Note--primary__tnFZB {
  background-color: var(--color-purple--100);
}

.Note_jds-Note--neutral__IOVI2 {
  background-color: var(--color-grey--050);
}

.Note_jds-Note--success__WSt9G {
  background-color: var(--color-success--100);
}

.Note_jds-Note--warning__hPqbt {
  background-color: var(--color-warning--100);
}

.Note_jds-Note--error__TXQcq {
  background-color: var(--color-error--100);
}

.Note_jds-Note__icon--success__v8HMq {
  color: var(--color-black);
}

.Note_jds-Note__icon--warning__8FwVO {
  color: var(--color-warning--500);
}

.Note_jds-Note__icon--error__Gx5Lw {
  color: var(--color-error--500);
}

.Note_jds-Note__body__TbCQO {
  flex: 1;
}

.Note_jds-Note__bodyTitle__PCf77 {
  font-weight: var(--semiBold);
}

.Note_jds-Note__closeButton__vLWj5 {
  align-self: flex-start;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

}
@layer ui {
  /* @define SwitchButton */

.switchButton_jds-SwitchButton__jDh1X {
  display: inline-flex;
  align-items: center;
  font-size: var(--normalBody);
  vertical-align: middle;
  cursor: pointer;
}

.switchButton_jds-SwitchButton__checker__uv4ED {
  --SwitchButton-width: 48px;
  --SwitchButton-height: 24px;

  display: inline-block;
  position: relative;
  box-sizing: content-box;
  width: var(--SwitchButton-width);
  min-width: var(--SwitchButton-width); /* useful to keep in some case to maintain width */
  height: var(--SwitchButton-height);
  transition: background 0.3s ease;
  border: 1px solid var(--color-grey--200);
  border-radius: var(--SwitchButton-height);
  background: var(--color-white);
}

.switchButton_jds-SwitchButton__checker__uv4ED::after {
  --SwitchButton-circle-margin: 2px;
  --SwitchButton-circle-diameter: calc(var(--SwitchButton-height) - 2 * var(--SwitchButton-circle-margin));
  --SwitchButton-circle-checkedposition:
    calc(
      var(--SwitchButton-width) - var(--SwitchButton-circle-diameter) - var(--SwitchButton-circle-margin)
    );

  content: "";
  display: inline-block;
  position: absolute;
  top: var(--SwitchButton-circle-margin);
  left: var(--SwitchButton-circle-margin);
  width: var(--SwitchButton-circle-diameter);
  height: var(--SwitchButton-circle-diameter);
  transition: left 0.3s ease, background 0.3s ease;
  border-radius: var(--radius-900);
  background: var(--color-grey--500);
}

.switchButton_jds-SwitchButton__input__jchIL:checked:not(:disabled) + .switchButton_jds-SwitchButton__checker__uv4ED {
  border-color: var(--themeMainColor, var(--color-black));
  background: var(--themeMainColor, var(--mainColor));
}

.switchButton_jds-SwitchButton__input__jchIL:checked + .switchButton_jds-SwitchButton__checker__uv4ED::after {
  left: var(--SwitchButton-circle-checkedposition);
  background: var(--themeButtonTextColor, var(--color-black));
}

.switchButton_jds-SwitchButton__input__jchIL:disabled + .switchButton_jds-SwitchButton__checker__uv4ED {
  background: var(--color-grey--100);
  cursor: initial;
}

.switchButton_jds-SwitchButton__input__jchIL:checked:disabled + .switchButton_jds-SwitchButton__checker__uv4ED {
  border-color: var(--color-grey--200);
  background: var(--color-grey--200);
}

.switchButton_jds-SwitchButton__input__jchIL:disabled + .switchButton_jds-SwitchButton__checker__uv4ED::after {
  background: var(--color-grey--200);
}

.switchButton_jds-SwitchButton__input__jchIL:not(:disabled) + .switchButton_jds-SwitchButton__checker__uv4ED {
  border-color: var(--color-black);
}

.switchButton_jds-SwitchButton__input__jchIL:checked:disabled + .switchButton_jds-SwitchButton__checker__uv4ED::after {
  background: var(--color-white);
}

.switchButton_jds-SwitchButton__jDh1X:hover > .switchButton_jds-SwitchButton__input__jchIL:not(:disabled) + .switchButton_jds-SwitchButton__checker__uv4ED,
.switchButton_jds-SwitchButton__input__jchIL:focus + .switchButton_jds-SwitchButton__checker__uv4ED {
  outline: 2px solid var(--color-black);
  outline-offset: -1px;
}

.switchButton_jds-SwitchButton__checker--withLeftLabel__TaMQE {
  margin-left: var(--spacer-100);
}

.switchButton_jds-SwitchButton__checker--withRightLabel___yMQ6 {
  margin-right: var(--spacer-100);
}

.switchButton_jds-SwitchButton__input__jchIL {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  border: 0;
}

.switchButton_jds-SwitchButton--small__HpjZV {
  font-size: var(--smallBody);
}

.switchButton_jds-SwitchButton--small__HpjZV .switchButton_jds-SwitchButton__checker__uv4ED {
  --SwitchButton-width: 38px;
  --SwitchButton-height: 18px;
}

}
@layer ui {
  /** @define FieldContainer */

.fieldContainer_jds-FieldContainer__ZIUSU {
  display: block;
  position: relative;
  min-width: 0; /* reset default overflow content behavior of a fieldset */
  margin: 0 0 var(--spacer-200);
  padding: 0;
  border: none;
  color: var(--color-black);
  font-size: var(--normalBody);
}

/* stylelint-disable plugin/selector-bem-pattern */
.fieldContainer_jds-FieldContainer--disabled__s1R2l .fieldContainer_jds-Field__notRequiredText__gIF9c {
  color: inherit;
}
/* stylelint-enable */

.fieldContainer_jds-FieldContainer__switchText__Tbcki {
  padding-top: var(--spacer-50);
  font-weight: var(--normal);
}

}
@layer ui {
  /** @define Field */

.field_jds-Field__label__2NFTo {
  display: block;
  margin-bottom: var(--spacer-50);
  font-size: var(--body2);
  font-weight: var(--semiBold);
  line-height: var(--body2-lh);
}

.field_jds-Field__label--isResized__93_ei {
  transform: translateY(6px);
  font-size: var(--minorBody);
  line-height: var(--minorBodyLineHeight);
}

.field_jds-Field__label--disabled__zlX5R {
  color: var(--color-grey--200);
}

.field_jds-Field__notRequiredText__bfS7o {
  color: var(--color-grey--600);
  font-weight: var(--normal);
}

.field_jds-Field__notRequiredText__bfS7o::before {
  content: "-";
  margin: 0 var(--spacer-50);
}

.field_jds-Field__label--disabled__zlX5R .field_jds-Field__notRequiredText__bfS7o {
  color: var(--color-grey--200);
}

.field_jds-Field__inputContainer__OUGsz {
  position: relative;
  width: 100%;
  min-height: var(--field-height);
}

.field_jds-Field__iconWrapper__1YDn6 {
  position: absolute;
  top: 50%;
  right: var(--spacer-200);
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  transform: translateY(-50%);
  border: none;
  outline: none;
  background: transparent;
  color: var(--color-black);
}

.field_jds-Field__iconWrapper--left__vGTS1 {
  right: auto;
  left: var(--spacer-200);
}

.field_jds-Field__iconWrapper--disabled__WshVl {
  color: var(--color-grey--200);
}

.field_jds-Field__iconWrapper--cursor__86gc_:not(.field_jds-Field__iconWrapper--disabled__WshVl) {
  color: var(--color-grey--200);
  cursor: pointer;
}

.field_jds-Field__iconWrapper--cursor__86gc_:not(.field_jds-Field__iconWrapper--disabled__WshVl):hover {
  color: var(--color-black);
}

.field_jds-Field__icon__YwksS {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
}

}
@layer ui {
  /** @define Push */

.push_jds-Push__auy84 {
  --Push-imageSize: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacer-300);
  border-radius: var(--radius-200);
}

.push_jds-Push--white__qIvg5 {
  background-color: var(--color-white);
}

.push_jds-Push--grey__bu4dH {
  background-color: var(--color-grey--025);
}

.push_jds-Push__content__YaQGb {
  width: 100%;
}

.push_jds-Push__text__TBINV {
  text-align: center;
}

.push_jds-Push__ctaWrapper__myJ4W {
  margin-top: var(--spacer-200);
  text-align: center;
}

.push_jds-Push__cta__2J9YA {
  width: 100%;
}

.push_jds-Push__decoration__yKs0_ {
  width: var(--Push-imageSize);
  min-width: var(--Push-imageSize);
  height: var(--Push-imageSize);
  margin-bottom: var(--spacer-300);
  -o-object-fit: contain;
     object-fit: contain;
}

@media (min-width: 48rem) {
  .push_jds-Push__auy84 {
    flex-direction: row;
  }

  .push_jds-Push__cta__2J9YA {
    width: auto;
  }

  .push_jds-Push--withImage__C_gwV .push_jds-Push__text__TBINV {
    text-align: left;
  }

  .push_jds-Push--withImage__C_gwV .push_jds-Push__ctaWrapper__myJ4W {
    text-align: left;
  }

  .push_jds-Push__decoration__yKs0_ {
    margin-right: var(--spacer-300);
    margin-bottom: 0;
  }
}

}
@layer ui {
  /** @define Input */

.input_jds-Input__x_yHZ {
  --Input-iconSize: 1.25rem;

  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  min-height: var(--field-height);
  padding: 0 var(--spacer-200);
  border: thin solid var(--color-grey--200);
  border-radius: var(--radius-200);
  color: var(--color-black);
  font-family: inherit;
  font-size: var(--normalBody);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
}

.input_jds-Input--rightIcon__Hcje3 {
  padding-right: calc(var(--Input-iconSize) + var(--spacer-200) + var(--spacer-150));
}

.input_jds-Input--leftIcon__BsHKd {
  padding-left: calc(var(--Input-iconSize) + var(--spacer-200) + var(--spacer-150));
}

.input_jds-Input__x_yHZ:disabled {
  border-color: var(--color-grey--200);
  background-color: var(--color-grey--025);
  color: var(--color-grey--200);
}

.input_jds-Input__x_yHZ::-moz-placeholder {
  opacity: 1;
  color: var(--color-grey--500);
}

.input_jds-Input__x_yHZ::placeholder {
  opacity: 1;
  color: var(--color-grey--500);
}

.input_jds-Input__x_yHZ:disabled::-moz-placeholder {
  color: var(--color-grey--200);
}

.input_jds-Input__x_yHZ:disabled::placeholder {
  color: var(--color-grey--200);
}

.input_jds-Input__x_yHZ:not(:disabled):hover,
.input_jds-Input__x_yHZ:not(:disabled):focus {
  outline: 2px solid var(--color-grey--500);
  outline-offset: -1px;
}

.input_jds-Input--hasError__L_Tsh {
  border-color: var(--color-error--500);
}

.input_jds-Input--hasError__L_Tsh:not(:disabled):hover,
.input_jds-Input--hasError__L_Tsh:not(:disabled):focus {
  outline-color: var(--color-error--500);
}

}
@layer ui {
  /** @define Illustration */
.Illustration_jds-Illustration--big__I2Ypf {
  height: 320px;
}

.Illustration_jds-Illustration--small__QCVX_ {
  height: 200px;
}

}
@layer ui {
  /** @define Image */

/* Legacy variables used in other repositories */
/* stylelint-disable plugin/selector-bem-pattern */

.image_jds-Image__MmccF {
  --image-box-tiny: 24px;
  --image-box-small: 40px;
  --image-box-medium: 64px;
  --image-box-big: 80px;
  --image-box-large: 128px;
}

.image_jds-Image__box--tiny__1MfO2 {
  width: var(--image-box-tiny);
  height: var(--image-box-tiny);
}

.image_jds-Image__box--small__9_Eyw {
  width: var(--image-box-small);
  height: var(--image-box-small);
}

.image_jds-Image__box--medium__ykp0v {
  width: var(--image-box-medium);
  height: var(--image-box-medium);
}

.image_jds-Image__box--big__b2oxK {
  width: var(--image-box-big);
  height: var(--image-box-big);
}

.image_jds-Image__box--large__zSe2O {
  width: var(--image-box-large);
  height: var(--image-box-large);
}

.image_jds-Image__box--circle__K3tNB {
  border-radius: var(--radius-900);
}

.image_jds-Image__box--undistorted__VcE43 {
  -o-object-fit: cover;
     object-fit: cover;
}

}
@layer ui {
  /*  @define CardTitle */
/* stylelint-disable plugin/selector-bem-pattern */
.cardTitle_jds-CardTitle__link__joX8c,
.cardTitle_jds-CardTitle__link__joX8c:visited,
.cardTitle_jds-CardTitle__link__joX8c:hover,
.cardTitle_jds-CardTitle__link__joX8c:active,
.cardTitle_jds-CardTitle__link__joX8c:focus,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:visited,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:hover,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:active,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:focus {
  outline: 0;
  text-decoration: none;
}

.cardTitle_jds-CardTitle__link__joX8c {
  font-weight: var(--bold);
}

.cardTitle_jds-CardTitle__link__joX8c::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: thin solid var(--color-grey--200);
  border-radius: var(--Card-radius);
  box-shadow: none;
}

.cardTitle_jds-CardTitle__link__joX8c:hover::after {
  border-color: var(--color-grey--300);
  box-shadow: var(--hover-shadow);
}

.cardTitle_jds-CardTitle__link__joX8c:active::after {
  border-color: var(--color-grey--400);
}

.cardTitle_jds-CardTitle__link__joX8c:focus::after {
  --CardBorder: 2px;
  border-color: var(--color-grey--200);
  outline: var(--CardBorder) solid var(--color-grey--400);
  outline-offset: var(--CardBorder);
}

@media (min-width: 64rem) {
  .cardTitle_jds-CardTitle--big__tFhdl {
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }
}

}
@layer ui {
  /* @define CardImage */

.cardImage_jds-CardImage__I_GO2 {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 16 / 9;
}

.cardImage_jds-CardImage__I_GO2::before {
  --CardImage-blurSize: 8px;

  content: "";
  position: absolute;
  z-index: 0;
  top: calc(var(--CardImage-blurSize) * -1);
  right: calc(var(--CardImage-blurSize) * -1);
  bottom: calc(var(--CardImage-blurSize) * -1);
  left: calc(var(--CardImage-blurSize) * -1);
  /* "--backgroundImageUrl" variable is defined at the html level (ex: <header style={{"--backgroundImageUrl": `url(url)`}}>) */
  background-image: var(--backgroundImageUrl);
  background-size: contain;
  filter: blur(var(--CardImage-blurSize)) brightness(75%);
}

.cardImage_jds-CardImage__image__jS8zd {
  position: absolute;
  top: 50%;
  width: 100%;
  height: auto;
  transform: translateY(-50%);
}

}
@layer ui {
  /* @define CardTagsListing */

.cardTagListing_jds-CardTagsListing__O6NOO {
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none;
}

.cardTagListing_jds-CardTagsListing__item__WHxmt {
  display: inline-block;
  margin: 0 var(--spacer-100) var(--spacer-50) 0;
}

}
@layer ui {
  /* @define CardTime */

.cardTime_jds-CardTime__5StAm {
  display: block;
  margin-top: var(--spacer-200);
  color: var(--color-grey--700);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
[class*="jds-Card--isDark"] .cardTime_jds-CardTime__5StAm {
  color: var(--color-grey--200);
}

@media (min-width: 48rem) {
  .cardTime_jds-CardTime__5StAm:not(.cardTime_jds-CardTime--isPush__yMi7n) {
    flex: 1 auto;
    align-self: flex-end;
    text-align: right;
  }
}

@media (min-width: 64rem) {
  .cardTime_jds-CardTime__5StAm:not(.cardTime_jds-CardTime--isPush__yMi7n) {
    flex: 1 0 auto;
  }
}

}
@layer ui {
  /* @define Listing */

.cardListing_jds-Listing__6rNno {
  display: block;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.cardListing_jds-Listing__Item__nw0fv {
  display: block;
}

.cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--removeSpacing__fD5lO) .cardListing_jds-Listing__Item__nw0fv:not(:first-child) {
  margin-top: var(--spacer-200);
}

@media (min-width: 48rem) {
  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) {
    display: block;
  }

  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) .cardListing_jds-Listing__Item__nw0fv {
    display: inline;
    margin-bottom: 0;
  }

  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) .cardListing_jds-Listing__Item__nw0fv > * {
    vertical-align: middle;
  }

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) .cardListing_jds-Listing__Item__nw0fv:not(:last-child)::after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    margin: 0 var(--spacer-100);
    background: var(--color-grey--500);
    vertical-align: middle;
  }
}

}
@layer ui {
  /* @define CardSpaceBetween */

.cardSpacebetween_jds-CardSpaceBetween__6e4V3 {
  align-items: flex-start;
}

.cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--removeSpacing__OQDQz) {
  margin-top: var(--spacer-200);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
.cardSpacebetween_jds-CardSpaceBetween__6e4V3 > *:not(:first-child) {
  margin-top: var(--spacer-200);
}

.cardSpacebetween_jds-CardSpaceBetween--hasDivider__mapyk {
  padding-top: var(--spacer-200);
  border-top: var(--border);
}

@media (min-width: 48rem) {
  .cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--isPush__TJ16i) {
    display: flex;
    justify-content: space-between;
    gap: var(--spacer-200);
  }

  /* stylelint-disable plugin/selector-bem-pattern */
  .cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--isPush__TJ16i) > *,
  .cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--isPush__TJ16i) > *:not(:first-child) {
    margin-top: 0;
  }
  /* stylelint-enable plugin/selector-bem-pattern */
}

}
@layer ui {
  /* @define CardFeedback */

.cardFeedback_jds-CardFeedback__Lr8jw {
  margin-top: var(--spacer-200);
  color: var(--mainColor);
  font-weight: var(--bold);
}

.cardFeedback_jds-CardFeedback__Text__aoaF2 {
  margin-right: var(--spacer-100);
  color: var(--color-black);
  vertical-align: middle;
}

}
@layer ui {
  /**  @define FilterBar **/

.filterBar_jds-FilterBar___Fpze {
  display: flex;
  flex-direction: column;
  box-shadow: var(--default-shadow);
}

.filterBar_jds-FilterBar__element__dSN3u {
  flex: 1;
  min-width: 0;
  margin: 0;
  margin-right: -1px;
  margin-bottom: -1px;
}

.filterBar_jds-FilterBar__element__dSN3u:hover,
.filterBar_jds-FilterBar__element__dSN3u:focus-within {
  z-index: 1;
}

.filterBar_jds-FilterBar__element__dSN3u:not(:first-child, :last-child) .jds-Select__control {
  border-radius: 0;
}

.filterBar_jds-FilterBar__element__dSN3u:first-child .jds-Select__control,
.filterBar_jds-FilterBar__element__dSN3u:first-child > div > input {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.filterBar_jds-FilterBar__element__dSN3u:last-child .jds-Select__control {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (min-width: 48rem) {
  .filterBar_jds-FilterBar___Fpze {
    flex-direction: row;
  }

  .filterBar_jds-FilterBar__element__dSN3u:first-child .jds-Select__control,
  .filterBar_jds-FilterBar__element__dSN3u:first-child > div > input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: var(--radius-200);
  }

  .filterBar_jds-FilterBar__element__dSN3u:last-child .jds-Select__control {
    border-top-left-radius: 0;
    border-top-right-radius: var(--radius-200);
    border-bottom-left-radius: 0;
  }
}

}
@layer ui {
  /** @define Pagination */

.pagination_jds-Pagination__5KFrR {
  display: flex;
  justify-content: center;
  padding: 0;
  list-style-type: none;
}

.pagination_jds-Pagination__wrapper__wxN4Q {
  margin: var(--spacer-400) 0 var(--spacer-800) 0;
}

.pagination_jds-Pagination__item__55Rn3 {
  display: flex;
  align-items: center;
  margin-right: var(--spacer-200);
  text-align: center;
}

.pagination_jds-Pagination__item__55Rn3:last-child {
  margin-right: 0;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button__GgDAw {
  background-color: transparent !important;
  color: var(--color-black) !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button__GgDAw:hover {
  border-color: transparent;
  background-color: var(--color-grey--100) !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__activeButton__djART,
.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button__GgDAw {
  min-width: 40px;
  border-color: transparent !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__activeButton__djART {
  color: var(--themeButtonTextColor, var(--color-black)) !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button--disabled__e4_45 {
  color: var(--color-grey--200) !important;
}

}
@layer ui {
  /* @define Tag */

.Tag_jds-Tag__S_kl6 {
  --Tag-radius: var(--radius-200);
  /* Needed here to prevent calc() with 0 (without unit) which doesn't work. */
  /* stylelint-disable-next-line length-zero-no-unit */
  --Tag-removable-button-width: 0rem;
  /* medium by default */
  --Tag-horizontal-spacing: var(--spacer-150);
  --Tag-vertical-spacing: var(--spacer-50);

  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  padding: var(--Tag-vertical-spacing) var(--Tag-horizontal-spacing);
  border-radius: var(--Tag-radius);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
}

.Tag_jds-Tag--small__645vS {
  --Tag-horizontal-spacing: var(--spacer-100);
  --Tag-vertical-spacing: 0;

  font-size: var(--smallBody);
}

.Tag_jds-Tag--large__bg77j {
  --Tag-horizontal-spacing: var(--spacer-200);
  --Tag-vertical-spacing: var(--spacer-100);
}

.Tag_jds-Tag--medium__svQfh,
.Tag_jds-Tag--large__bg77j {
  font-size: var(--normalBody);
}

.Tag_jds-Tag--withIcon__zJH9U {
  padding-left: var(--spacer-100);
}

.Tag_jds-Tag--removable__KmanL[class*="small"] {
  /* padding-left + icon-width + padding-right */
  --Tag-removable-button-width: calc(var(--spacer-50) + var(--icon-size-small) + var(--spacer-150));
}

.Tag_jds-Tag--removable__KmanL[class*="medium"] {
  /* padding-left + icon-width + padding-right */
  --Tag-removable-button-width: calc(var(--spacer-50) + var(--icon-size-medium) + var(--spacer-150));
}

.Tag_jds-Tag--removable__KmanL[class*="large"] {
  /* padding-left + icon-width + padding-right */
  --Tag-removable-button-width: calc(var(--spacer-50) + var(--icon-size-big) + var(--spacer-150));
}

.Tag_jds-Tag--removable__KmanL {
  /* margin-left + Remove icon size */
  padding-right: calc(var(--spacer-50) + var(--Tag-removable-button-width));
}

.Tag_jds-Tag__Icon__XzZfg {
  margin-right: var(--spacer-100);
}

.Tag_jds-Tag__Label__WNaZc {
  /* https://css-tricks.com/almanac/properties/l/line-clamp/ */
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Tag_jds-Tag__RemoveButton__yNPo2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: var(--Tag-removable-button-width);
  padding-right: var(--spacer-100);
  padding-left: var(--spacer-50);
  border: 0;
  border-top-right-radius: var(--Tag-radius);
  border-bottom-right-radius: var(--Tag-radius);
  background-color: transparent;
  color: inherit;
  cursor: pointer;
}

.Tag_jds-Tag__RemoveButton__yNPo2:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.Tag_jds-Tag__RemoveButton__yNPo2:focus {
  background-color: rgba(0, 0, 0, 0.2);
}

.Tag_jds-Tag--cctheme__CP2rD .Tag_jds-Tag__RemoveButton__yNPo2 {
  color: var(--color-white);
}

/* @TODO: handle colors variant here */

.Tag_jds-Tag--default__4MLCo {
  background-color: var(--color-grey--100);
  color: var(--color-grey--700);
}

.Tag_jds-Tag--cctheme__CP2rD {
  background-color: var(--mainColor);
  color: var(--color-black);
}

.Tag_jds-Tag--purple__o_4tb {
  background-color: var(--color-purple--100);
  color: var(--color-purple--700);
}

.Tag_jds-Tag--warning__f1lkM {
  background-color: var(--color-warning--100);
  color: var(--color-warning--700);
}

.Tag_jds-Tag--pink__sY_QO {
  background-color: var(--color-pink--100);
  color: var(--color-pink--800);
}

.Tag_jds-Tag--cyan__ZpEK6 {
  background-color: var(--color-cyan--050);
  color: var(--color-cyan--800);
}

.Tag_jds-Tag--yellow__A7uJy {
  background-color: var(--color-yellow--050);
  color: var(--color-yellow--800);
}

.Tag_jds-Tag--dark__7gaeu {
  background-color: var(--color-grey--700);
  color: var(--color-white);
}

}
